<template>
  <div class="myPublish">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="activityItem" v-for="(item, index) in listData" :key="index">
        <div class="item-l"><img :src="item.activityPicture" alt="" /></div>
        <div class="item-r">
          <h3>{{ item.activityName }}</h3>
          <p>报名开始时间：{{ item.applyStartDate }}</p>
          <p>活动开始时间：{{ item.startDate }}</p>
        </div>
        <div class="status">{{ getOption(item.auditStatus) }}</div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myPublishActivityUrl } from "./api.js";
import { getOption } from "@/utils/utils.js";
import { activityStatus, auditStatusOps } from "./map.js";
export default {
  name: "myPublish",
  data() {
    return {
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {
    // this.getActivityList();
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "orderDetail",
        query: {
          activityId: item.activityId,
          isApply: 1,
          applyId: item.applyId,
        },
      });
    },
    getOption(status) {
      if (getOption(status, auditStatusOps, "key")["value"]) {
        return getOption(status, auditStatusOps, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios
        .get(`${myPublishActivityUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = item.activityPicture.ossResize(400);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getActivityList();
    },
  },
};
</script>
<style lang="less" scoped>
.myPublish {
  padding: 40px;
  .activityItem {
    display: flex;
    height: 208px;
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    .status {
      position: absolute;
      text-align: center;
      background: cornflowerblue;
      color: #fff;
      font-size: 24px;
      height: 40px;
      line-height: 40px;
      width: 100px;
      left: 0;
      top: 10px;
    }
    .item-l {
      width: 190px;
      height: 190px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-r {
      flex: 1;
      h3 {
        max-width: 400px;
        font-size: 40px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      p {
        font-size: 30px;
        color: #999;
        margin: 10px 0;
      }
    }
  }
}
</style>
